var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"homePage "},[_c('div',{staticClass:"main sc-width"},[_c('div',{staticClass:"left"},[_c('div',{staticClass:"top"},[_c('div',{staticClass:"logo"},[(_vm.is_administrator)?_c('div',{staticClass:"edit-avatar",on:{"click":function($event){_vm.modal = true}}},[_vm._v(" Edit ")]):_vm._e(),_c('img',{attrs:{"src":_vm.myCompanyDetails.avatar.url}})]),_c('div',{staticClass:"name"},[_vm._v(_vm._s(_vm.myCompanyDetails.company_name))])]),_c('div',{staticClass:"info"},[_c('div',{class:['item', { active: _vm.activeIndex === 'basic' }],on:{"click":function($event){_vm.activeIndex = 'basic'}}},[_vm._v(" Basic Information ")]),_c('div',{class:[
            'item',
            {
              active:
                ['detail', 'products', 'companies'].indexOf(_vm.activeIndex) > -1
            }
          ],on:{"click":function($event){_vm.activeIndex = 'detail'}}},[_vm._v(" Company Details ")]),('exhibitor' === _vm.role)?_c('div',{class:['item', { active: _vm.activeIndex === 'product' }],on:{"click":function($event){_vm.activeIndex = 'product'}}},[_vm._v(" Products ")]):_vm._e(),('exhibitor' === _vm.role)?_c('div',{class:['item', { active: _vm.activeIndex === 'factory' }],on:{"click":function($event){_vm.activeIndex = 'factory'}}},[_vm._v(" Factories ")]):_vm._e(),('exhibitor' === _vm.role)?_c('div',{class:['item', { active: _vm.activeIndex === 'document' }],on:{"click":function($event){_vm.activeIndex = 'document'}}},[_vm._v(" Documents ")]):_vm._e(),_c('div',{class:['item', { active: _vm.activeIndex === 'member' }],on:{"click":function($event){_vm.activeIndex = 'member'}}},[_vm._v(" Members ")])])]),(_vm.activeIndex === 'basic')?_c('basic'):_vm._e(),(_vm.activeIndex === 'detail')?_c('detail',{on:{"analysis":function($event){return _vm.analysis($event)}}}):_vm._e(),(_vm.activeIndex === 'product' && 'Basic' != _vm.myCompanyDetails.level)?_c('product'):_vm._e(),(_vm.activeIndex === 'product' && 'Basic' === _vm.myCompanyDetails.level)?_c('basicProduct'):_vm._e(),(_vm.activeIndex === 'factory')?_c('factory'):_vm._e(),(_vm.activeIndex === 'document')?_c('document'):_vm._e(),(_vm.activeIndex === 'member')?_c('member'):_vm._e(),(_vm.activeIndex === 'products')?_c('products',{on:{"analysis-back":function($event){return _vm.back($event)}}}):_vm._e(),(_vm.activeIndex === 'companies')?_c('companies',{on:{"analysis-back":function($event){return _vm.back($event)}}}):_vm._e()],1),_c('Modal',{attrs:{"title":"Change Avatar","footer-hide":"","mask-closable":false},model:{value:(_vm.modal),callback:function ($$v) {_vm.modal=$$v},expression:"modal"}},[_c('Alert',{attrs:{"show-icon":""}},[_vm._v(" We recommend that you upload a photo with a minimum dimension of 400 x 400. ")]),_c('Cropper',{ref:"cropper",attrs:{"stencil-props":{
        aspectRatio: 1
      },"classname":"cropper","src":_vm.image}}),_c('Button',{staticClass:"mr-20",on:{"click":function($event){return _vm.$refs.file.click()}}},[_c('input',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],ref:"file",attrs:{"type":"file","accept":"image/*"},on:{"change":function($event){return _vm.uploadImage($event)}}}),_vm._v(" Choose image ")]),_c('Button',{attrs:{"loading":_vm.uplodLoading,"disabled":!_vm.image,"type":"primary"},on:{"click":_vm.crop}},[_vm._v(" Upload ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }