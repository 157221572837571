<template>
  <div class="right">
    <div class="top">
      <div class="return" @click="back()">
        <div class="pic"><img src="@/assets/back.png" alt="" /></div>
        <div class="word">Return to Details</div>
      </div>
      <div class="search">
        <div class="input">
          <input type="text" place="Search for a product..." />
        </div>
        <div class="btn">SEARCH</div>
      </div>
      <div class="totle">Total Views <font color="">12</font></div>
    </div>
    <div class="bordered"></div>
    <div class="content">
      <div class="title">
        <div class="item name">PRODUCT NAME</div>
        <div class="item count">PAGE VIEWS</div>
        <div class="item date">DATE VIEWED</div>
      </div>
      <div class="body">
        <div class="item" v-for="item in list" :key="item.id">
          <div class="name">{{ item.marketing_name }}</div>
          <div class="count">{{ item.view_count }}</div>
          <div class="date">{{ item.recent_time }}</div>
        </div>
      </div>
    </div>
    <div class="page">
      <Page
        transfer
        :page-size="limit"
        :page-size-opts="[10, 15, 20]"
        :total="total"
        show-sizer
        show-elevator
        show-total
        @on-change="pageChange"
        @on-page-size-change="pageSizeChange"
      />
    </div>
  </div>
</template>

<script>
import api from "@/api";
import moment from "moment";
const { _queryAnalysisProducts } = api;

export default {
  name: "analysis-products",
  data() {
    return {
      modType: "card",
      page: 1,
      limit: 10, // must in 5-20
      total: 0,
      list: [],
      loading: false,
      companyCol: [
        {
          title: "PRODUCT NAME",
          key: "marketing_name",
          slot: "marketing_name"
        },
        {
          title: "PAGE VIEWS",
          key: "view_count"
        },
        {
          title: "DATE VIEWED",
          key: "recent_time"
        }
      ],
      filter: {
        name: ""
      }
    };
  },
  created() {
    this.queryList();
  },

  methods: {
    back() {
      this.$emit("analysis-back");
    },
    clearSearch() {
      this.page = 1;
      this.curBar = "";
      this.filter.name = "";
      this.queryList();
    },

    handleSearch() {
      this.page = 1;
      this.queryList();
    },

    pageChange(page) {
      this.page = page;
      this.queryList();
    },

    pageSizeChange(limit) {
      this.limit = limit;
      this.queryList();
    },

    queryList() {
      this.loading = true;
      this.list = [];
      _queryAnalysisProducts({
        page: this.page,
        limit: this.limit,
        ...this.filter
      })
        .then(response => {
          const { data } = response;
          // this.list = data.data;
          this.list = data.data.map(item => {
            item.recent_time = moment
              .utc(item.recent_time)
              .local()
              .format("YYYY-MM-DD HH:mm");
            return item;
          });
          this.total = data.total;
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style lang="less" scoped>
.homePage {
  background: #ffffff;
  margin-top: 120px;
  display: flex;
}
.sc-width {
  margin: 0 auto;
}

.right {
  width: 1000px;
  .top {
    display: flex;
    justify-content: space-between;
    .return {
      width: 150px;
      height: 30px;
      background: #ef7f21;
      border-radius: 5px;
      text-align: center;
      cursor: pointer;

      .pic {
        width: 8px;
        height: 10px;
        img {
          width: 20px;
          height: 20px;
          position: relative;
          top: 8px;
          left: 0px;
        }
      }
      .word {
        position: relative;
        top: -2px;
        font-size: 14px;

        font-weight: 500;
        color: #ffffff;
        line-height: 18px;
      }
    }
    .search {
      display: flex;
      .input {
        input {
          width: 400px;
          height: 30px;
          background: #ffffff;
          border: 1px solid #ef7f21;
          border-radius: 5px;
        }
      }
      .btn {
        width: 120px;
        height: 30px;
        background: #ef7f21;
        border-radius: 5px;
        text-align: center;
        font-size: 14px;

        font-weight: 500;
        color: #ffffff;
        line-height: 18px;
        padding-top: 7px;
        margin-left: 20px;
      }
    }
    .total {
      font-size: 16px;

      font-weight: 400;
      color: #999999;
      line-height: 18px;
    }
  }
  .bordered {
    height: 1px;
    background: #999999;
    margin-top: 30px;
    margin-bottom: 25px;
  }
  .content {
    .title {
      display: flex;
      height: 30px;
      background: #53575c;
      /*justify-content: space-around;*/
      padding-top: 6px;
      .item {
        font-size: 14px;

        font-weight: 400;
        color: #ffffff;
        line-height: 18px;
        width: 33%;
        text-align: center;
      }
    }
    .body {
      .item {
        display: flex;
        height: 30px;
        /*background: #F5F5F5;*/
        /*justify-content: space-around;*/
        padding-top: 5px;
        div {
          width: 33%;
          text-align: center;
        }
      }
    }
  }
}
.page {
  float: right;
  margin-top: 30px;
}
.company-link {
  color: @primary-color;
  cursor: pointer;
}
</style>
