<template>
  <div class="homePage ">
    <div class="main">
      <div class="left">
        <div class="top">
          <div class="logo">
            <div
              v-if="is_administrator"
              @click="modal = true"
              class="edit-avatar"
            >
              Edit
            </div>
            <img :src="myCompanyDetails.avatar.url" />
          </div>
          <div class="name">{{ myCompanyDetails.company_name }}</div>
        </div>
        <div class="info">
          <div
            :class="['item', { active: activeIndex === 'basic' }]"
            @click="activeIndex = 'basic'"
          >
            Basic Information
          </div>
          <div
            :class="[
              'item',
              {
                active:
                  ['detail', 'products', 'companies'].indexOf(activeIndex) > -1
              }
            ]"
            @click="activeIndex = 'detail'"
          >
            Company Details
          </div>
          <div
            :class="['item', { active: activeIndex === 'product' }]"
            @click="activeIndex = 'product'"
            v-if="'exhibitor' === role"
          >
            Products
          </div>
          <div
            :class="['item', { active: activeIndex === 'factory' }]"
            @click="activeIndex = 'factory'"
            v-if="'exhibitor' === role"
          >
            Factories
          </div>
          <div
            :class="['item', { active: activeIndex === 'document' }]"
            @click="activeIndex = 'document'"
            v-if="'exhibitor' === role"
          >
            Documents
          </div>
          <div
            :class="['item', { active: activeIndex === 'member' }]"
            @click="activeIndex = 'member'"
          >
            Members
          </div>
        </div>
      </div>

      <basic v-if="activeIndex === 'basic'" />
      <detail v-if="activeIndex === 'detail'" @analysis="analysis($event)" />
      <product v-if="activeIndex === 'product'" />
      <factory v-if="activeIndex === 'factory'" />
      <document v-if="activeIndex === 'document'" />
      <member v-if="activeIndex === 'member'" />
      <products
        v-if="activeIndex === 'products'"
        @analysis-back="back($event)"
      />
      <companies
        v-if="activeIndex === 'companies'"
        @analysis-back="back($event)"
      />
    </div>
    <Modal
      v-model="modal"
      title="Change Avatar"
      footer-hide
      :mask-closable="false"
    >
      <Alert show-icon>
        We recommend that you upload a photo with a minimum dimension of 400 x
        400.
      </Alert>

      <Cropper
        :stencil-props="{
          aspectRatio: 1
        }"
        classname="cropper"
        :src="image"
        ref="cropper"
      />

      <Button class="mr-20" @click="$refs.file.click()">
        <input
          v-show="false"
          type="file"
          ref="file"
          @change="uploadImage($event)"
          accept="image/*"
        />
        Choose image
      </Button>

      <Button
        :loading="uplodLoading"
        :disabled="!image"
        @click="crop"
        type="primary"
      >
        Upload
      </Button>
    </Modal>
  </div>
</template>

<script>
// import api from "@/api";
// const {
// } = api;
import basic from "./company-profile-basic";
import detail from "./company-profile-detail";
import product from "./company-profile-product";
import factory from "./company-profile-factory";
import document from "./company-profile-document";
import member from "./company-profile-member";
import products from "./analysis-products";
import companies from "./analysis-companies";
import { mapState } from "vuex";
import { Cropper } from "vue-advanced-cropper";
import { dataURLtoBlob, blobToFile } from "@/utils/helper";
import { MatchMedia } from "vue-component-media-queries";
import api from "@/api";
import S3 from "aws-s3";
const {
  _queryMyCompany,
  // _editMyCompanyBanner,
  _editMyCompanyAvatar
} = api;
const uploader = new S3({
  bucketName: "connexima",
  dirName: "images",
  region: "us-west-1",
  accessKeyId: "AKIA6KR6UZLLVZYGDEZM",
  secretAccessKey: "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"
});

export default {
  computed: {
    ...mapState("user", ["token", "role", "is_administrator"]),
    MatchMedia
  },
  components: {
    basic,
    detail,
    product,
    factory,
    document,
    member,
    products,
    companies,
    Cropper
  },
  data() {
    return {
      mobileWidth: window.innerWidth < 800,
      form: {},
      activeIndex: "detail",
      id: null,
      activeTab: "",
      image: null,
      uplodLoading: false,
      myCompanyDetails: {},
      modal: false
    };
  },
  // created:{
  //
  // },
  mounted() {
    this.queryMyCompany();
    if (Object.keys(this.$route.query).length > 0) {
      this.activeIndex = this.$route.query.type;
    }
  },
  methods: {
    back() {
      this.activeIndex = "detail";
    },
    analysis(data) {
      this.activeIndex = data;
    },
    queryMyCompany() {
      this.loading = true;
      _queryMyCompany()
        .then(response => {
          const { data } = response;

          this.id = data.id;
          this.myCompanyDetails = data;
          this.form.company_landing_image = data.landing_image?.url;
          this.form.view_history_count = data.view_history_count;
          this.form.viewed_product_history_count =
            data.viewed_product_history_count;
        })
        .catch(({ message }) => {
          this.$Message.error(message);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    crop() {
      const { canvas } = this.$refs.cropper.getResult();
      const base64Data = canvas.toDataURL();
      const blob = dataURLtoBlob(base64Data);
      const file = blobToFile(blob);

      this.uplodLoading = true;
      uploader
        .uploadFile(file)
        .then(data => {
          this.modal = false;
          this.updateAvatar(data.location);
        })
        .catch(() => {
          this.$Message.error("upload error");
        })
        .finally(() => {
          this.uplodLoading = false;
        });
    },

    uploadImage(event) {
      var input = event.target;
      if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.onload = e => {
          this.image = e.target.result;
        };
        reader.readAsDataURL(input.files[0]);
      }
    },

    updateAvatar(url) {
      this.form.company_avatar = url;
      _editMyCompanyAvatar({
        ...this.form
      })
        .then(() => {
          this.myCompanyDetails.avatar.url = url;
          this.$Message.success("success");
        })
        .catch(({ message }) => {
          this.$Message.error(message);
        });
    }
  },
  filters: {}
};
</script>

<style lang="less" scoped>
.homePage {
  background: #ffffff;
  margin-top: 10px;
  display: flex;
}
.sc-width {
  margin: 0 auto;
}
.main {
  /*display: flex;*/
  margin: 50px auto;
  width: 100%;
  .left {
    /*width:300px;*/
    border-radius: 5px;
    border: 1px solid #efefef;
    margin-right: 30px;
    .top {
      text-align: center;
      height: 120px;
      background: linear-gradient(135deg, #a25d72, #2d3b81);
      opacity: 0.9;
      border-radius: 5px;
      display: flex;
      .logo {
        position: relative;
        top: 20px;
        left: 20px;
        background: #ffffff;
        height: 80px;
        width: 80px;
        &:hover {
          .edit-avatar {
            opacity: 1;
          }
        }
        .edit-avatar {
          position: absolute;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.44);
          display: flex;
          align-items: center;
          justify-content: center;
          color: #fff;
          opacity: 0;
          cursor: pointer;
        }
        img {
          margin-top: 10px;
          width: 60px;
          height: 60px;
        }
      }
      .name {
        font-size: 16px;

        font-weight: 500;
        color: #ffffff;
        line-height: 18px;
        position: relative;
        top: 50px;
        margin-left: 50px;
      }
    }
    .info {
      .item {
        height: 60px;
        text-align: center;
        padding-top: 22px;
        font-size: 18px;

        font-weight: 500;
        color: #53575c;
        line-height: 18px;
        cursor: pointer;
      }
      .active {
        background: #fdecde;
        /*opacity: 0.15;*/
      }
    }
  }
  .right {
    /*width:1000px;*/
    border-radius: 5px;
    border: 1px solid #efefef;
    .title {
      font-size: 24px;

      font-weight: 500;
      color: #ef7f21;
      line-height: 18px;
    }
    .divide {
      width: 1070px;
      height: 1px;
      background: #ef7f21;
    }
  }
}

.cropper {
  width: 100%;
  height: 300px;
  border: solid 1px #ccc;
  margin-bottom: 20px;
}
</style>
