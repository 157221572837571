<template>
  <div class="right">
    <div class="main">
      <div class="top">
        <div class="search">
          <div class="total">
            Total Members <font color="#EF7F21">{{ total }}</font>
          </div>
          <div class="input">
            <input type="text" v-model="filter.search" />
          </div>
          <div class="button" @click="handleSearch">Search</div>
        </div>
      </div>
      <div class="divided main"></div>
      <div class="main list-data" v-if="list.length > 0">
        <div class="title">
          <div class="item name">Name</div>
          <div class="item tel">Telephone</div>
          <div class="item status">Status</div>
          <div class="item action">Action</div>
        </div>
        <div class="content">
          <div class="item" v-for="item in list" :key="item.id">
            <div class=" name">{{ item.first_name }} {{ item.last_name }}</div>
            <div class=" phone">{{ item.phone }}</div>
            <div class=" status">{{ item.status }}</div>
            <div class=" action">
              <div class="edit-doit" @click="edit(item)">
                <img src="@/assets/edit.png" />
              </div>
              <div class="delete-doit" @click="removeDocument(item)">
                <img src="@/assets/delete.png" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="no-data" v-else>
        <div class="pic"><img src="@/assets/no-data.png" /></div>
        <div class="desc">No Data</div>
      </div>
      <div class="page sc-width">
        <Page
          transfer
          :current="page"
          :page-size="limit"
          :total="total"
          show-elevator
          show-total
          @on-change="pageChange"
          @on-page-size-change="pageSizeChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api";
const { _queryMemberList, _removeMember, _changeActive } = api;
export default {
  name: "test-company-profile-member",

  computed: {},
  components: {},
  data() {
    return {
      mobileWidth: window.innerWidth < 800,
      form: {},
      activeIndex: "basic",
      page: 1,
      limit: 12, // must in 5-20
      total: 0,
      loading: false,
      list: [],
      filter: { search: "" }
    };
  },
  created() {
    this.queryMemberList();
  },
  mounted() {},
  methods: {
    edit(item) {
      this.$router.push(`/member/${item.id}/edit`);
    },

    handleSearch() {
      this.page = 1;
      this.queryMemberList();
    },
    queryMemberList() {
      this.loading = true;

      _queryMemberList({
        page: this.page,
        limit: this.limit,
        ...this.filter
      })
        .then(response => {
          const { data } = response;
          this.list = data.data;
          this.total = data.total;
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    pageChange(page) {
      this.page = page;
      this.queryMemberList();
    },

    pageSizeChange(limit) {
      this.limit = limit;
      this.queryMemberList();
    },
    changeStatus(item) {
      var willState = item.state === 1 ? 0 : 1;

      _changeActive({
        id: item.id,
        state: willState
      })
        .then(() => {
          this.list.forEach((ite, index) => {
            if (ite.id === item.id)
              this.$set(this.list[index], "state", willState);
          });
        })
        .catch(error => {
          console.log(error);
        });
    },

    removeMember(item) {
      this.$Modal.confirm({
        loading: true,
        title: "Confirm Delete ",
        content: "Are you sure you want to delete this relationship?",
        onOk: () => {
          _removeMember({
            id: item.id
          })
            .then(() => {
              this.$Message.success("success");
              this.queryMemberList(); // refresh
            })
            .catch(({ message }) => {
              this.$Message.error(message);
            })
            .finally(() => {
              this.$Modal.remove();
            });
        }
      });
    }
  },
  filters: {}
};
</script>

<style lang="less" scoped>
@media (min-width: 1600px) {
  .main {
    width: 1018px;
    margin: 0 auto;
  }
  .top .search {
    width: 650px;
    .input {
      width: 380px;
    }
  }
  .list-data {
    margin-left: 10px;
    .product {
      width: 229px;
      margin-right: 15px;
      .product-top {
        width: 229px;
        img {
          width: 100%;
        }
      }
    }
  }
}
@media (max-width: 1600px) and (min-width: 1400px) {
  .main {
    width: 750px;
    margin: 0 auto;
  }
  .top .search {
    width: 520px;
    .input {
      width: 260px;
    }
  }
  .list-data {
    margin-left: 10px;
    .product {
      width: 210px;
      margin-right: 15px;
      .product-top {
        width: 210px;
        img {
          width: 100%;
        }
      }
    }
  }
}
@media (max-width: 1400px) and (min-width: 1200px) {
  .main {
    width: 700px;
    margin: 0 auto;
  }
  .top .search {
    width: 480px;
    .input {
      width: 200px;
    }
  }
}
@media (max-width: 1200px) {
}
.homePage {
  background: #ffffff;
  margin-top: 120px;
  display: flex;
}
.sc-width {
  margin: 0 auto;
}

.top {
  display: flex;
  justify-content: space-between;
  margin-top: 67px;
  margin-left: 48px;
  .search {
    justify-content: space-between;
    display: flex;
    .total {
      padding-top: 5px;
    }
    .input {
      height: 30px;
      background: #ffffff;
      border: 1px solid #ef7f21;
      border-radius: 5px;
      padding-left: 3px;
      padding-right: 3px;
      input {
        width: 100%;
        height: 100%;
        outline: none;
        border: none;
      }
    }
    .button {
      width: 120px;
      height: 30px;
      background: #ef7f21;
      border-radius: 5px;
      font-size: 16px;

      font-weight: 500;
      color: #ffffff;
      line-height: 18px;
      text-align: center;
      padding-top: 7px;
      cursor: pointer;
    }
  }
  .add {
    width: 140px;
    height: 30px;
    background: #ef7f21;
    border-radius: 5px;
    font-size: 14px;

    font-weight: 500;
    color: #ffffff;
    line-height: 18px;
    text-align: center;
    padding-top: 7px;
  }
}
.divided {
  height: 1px;
  background: #999999;
  margin-top: 30px;
  margin-bottom: 26px;
}
.main {
  .title {
    display: flex;
    background: #53575c;
    height: 40px;
    .item {
      font-size: 16px;

      font-weight: 500;
      color: #ffffff;
      padding-top: 7px;
      padding-left: 20px;
    }
    .name {
      width: 300px;
    }
    .tel {
      width: 200px;
    }
    .status {
      width: 300px;
    }
    .action {
      width: 150px;
    }
  }
  .content {
    .item {
      display: flex;
      height: 40px;
      font-size: 14px;

      /*font-weight: 500;*/
      padding-top: 7px;
      padding-left: 20px;
    }
    .name {
      width: 300px;
    }
    .phone {
      width: 200px;
    }
    .status {
      width: 300px;
    }
    .action {
      width: 150px;
      display: flex;

      .edit-doit {
        background: #ef7f21;
        width: 20px;
        height: 20px;
        text-align: center;
        margin-right: 15px;
        img {
          width: 13px;
          height: 13px;
        }
      }
      .delete-doit {
        background: #e61a15;
        width: 20px;
        height: 20px;
        text-align: center;
        img {
          width: 13px;
          height: 13px;
        }
      }
    }
  }
}
.page {
  margin-top: 30px;
  text-align: center;
}
.no-data {
  text-align: center;
  margin-top: 30px;
}
</style>
