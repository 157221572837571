<template>
  <div class="right">
    <div class="main">
      <div class="top">
        <div class="search">
          <div class="total">
            Total Products <font color="#EF7F21">{{ total }}</font>
          </div>
          <div class="input">
            <input
              type="text"
              v-model="filter.search"
              placeholder="Search for a product ..."
            />
          </div>
          <div class="button" @click="handleSearch">Search</div>
        </div>
        <router-link to="/products/create">
          <div class="add">+Add Product</div>
        </router-link>
      </div>
      <div class="divided main"></div>
      <div class="main list-data" v-if="list.length > 0">
        <div
          :class="[
            'product',
            { 'active-product': activeProduct === product.id }
          ]"
          :key="product.id"
          v-for="product in list"
          @mouseover="productMouseOver(product.id)"
          @mouseleave="productMouseLeave()"
          @click="
            product.status === -3
              ? $router.push(`/products/create?draft=${product.id}`)
              : $router.push(`/products/${product.id}/edit`)
          "
        >
          <div class="product-top">
            <img :src="product.images[0].url" />
          </div>
          <div class="bottom">
            <div class="logo">
              <div class="back">
                <img :src="product.company.avatar.url" />
              </div>
            </div>
            <div class="title">{{ product.marketing_name }}</div>
            <!--              <div class="label">PRODUCT CATEGORY</div>-->
            <div class="value">{{ product.product_type }}</div>
            <!--                <div class="label">ORIGIN</div>-->
            <!--                <div class="value">China</div>-->
            <div class="tag" :style="{ background: product.tags[0].color }">
              {{ product.tags[0].name }}
            </div>
          </div>
          <div class="operate-divided"></div>
          <div class="status">
            <span class="key">
              Status:
            </span>
            <span class="value" v-if="product.status === -3">
              Incomplete
            </span>
            <span class="value" v-if="product.status === -2">
              Unpublished
            </span>
            <span
              @click="
                $Modal.info({
                  title: 'Rejected reason',
                  content: product.reason
                })
              "
              class="value fail"
              v-if="product.status === -1"
            >
              Rejected<Icon class="tip" type="ios-information-circle-outline" />
            </span>
            <span class="value" v-if="product.status === 0">
              Pending Approval
            </span>
            <span class="value success" v-if="product.status === 1">
              Approved
            </span>
            <span class="value" v-if="product.status === 2">
              Published
            </span>
          </div>
          <div class="operate action">
            <Tooltip content="Edit" placement="top">
              <Button
                v-if="product.status === -3"
                @click="$router.push(`/products/create?draft=${product.id}`)"
                size="small"
                icon="md-create"
                class="mr-10"
              />
              <Button
                v-else
                @click="$router.push(`/products/${product.id}/edit`)"
                size="small"
                icon="md-create"
                class="mr-10"
                :disabled="product.redirect_url ? true : false"
              />
            </Tooltip>

            <Tooltip
              v-if="product.status === -2 || product.status === 1"
              content="Publish"
              placement="top"
            >
              <Button @click="publish(product.id)" size="small" class="mr-10">
                <img
                  alt=""
                  class="icon"
                  style="width: 16px;height: 16px;margin-top:3px"
                  src="@/assets/product/publish.png"
                />
              </Button>
            </Tooltip>

            <Tooltip
              v-if="product.status === 2"
              content="Unpublish"
              placement="top"
            >
              <Button @click="unpublish(product.id)" size="small" class="mr-10">
                <img
                  style="height:24px;width:24px"
                  alt=""
                  class="icon"
                  src="@/assets/product/unpublish.png"
                />
              </Button>
            </Tooltip>

            <Tooltip content="Duplicate" placement="top">
              <Button
                class="mr-10"
                @click="copyProduct(product)"
                size="small"
                icon="ios-copy-outline"
              />
            </Tooltip>

            <Tooltip content="Delete" placement="top">
              <Button
                @click="removeProduct(product)"
                size="small"
                type="error"
                ghost
                icon="ios-trash"
              />
            </Tooltip>
          </div>
        </div>
      </div>
      <div class="no-data" v-else>
        <div class="pic"><img src="@/assets/no-data.png" /></div>
        <div class="desc">No Data</div>
      </div>
      <div class="page sc-width">
        <Page
          transfer
          :current="page"
          :page-size="limit"
          :total="total"
          show-elevator
          show-total
          @on-change="pageChange"
          @on-page-size-change="pageSizeChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api";
const {
  _queryMyProductList,
  _createDraft,
  _createProduct,
  _unpublishProduct,
  _publishProduct,
  _queryProduct,
  _removeProduct
} = api;
export default {
  name: "test-company-profile-product",

  computed: {},
  components: {},
  data() {
    return {
      mobileWidth: window.innerWidth < 800,
      form: {},
      activeIndex: "basic",
      page: 1,
      limit: 12, // must in 5-20
      total: 0,
      loading: false,
      list: [],
      filter: { search: "" },
      activeProduct: null
    };
  },
  created() {
    this.queryMyProductList();
  },
  mounted() {},
  methods: {
    productMouseOver(e) {
      this.activeProduct = e;
    },
    productMouseLeave() {
      this.activeProduct = null;
    },
    handleSearch() {
      this.page = 1;
      this.queryMyProductList();
    },
    removeProduct(item) {
      this.$Modal.confirm({
        loading: true,
        title: "Confirm Delete",
        content: "Are you sure to delete this item?",
        onOk: () => {
          _removeProduct({
            id: item.id
          })
            .then(() => {
              this.$Modal.remove();
              this.$Message.success("success");
              this.queryMyProductList(); // refresh
            })
            .catch(({ message }) => {
              this.$Modal.remove();
              this.$Message.error(message);
            });
        }
      });
    },
    copyProduct(item) {
      this.$Modal.confirm({
        loading: true,
        title: "Confirm Duplicate Listing",
        content: "Are you sure you want to duplicate this product listing?",
        onOk: () => {
          _queryProduct({ id: item.id }).then(response => {
            const { data } = response;
            let form1 = {};
            let form2 = {};
            let form3 = {};
            let form4 = {};
            let form5 = {};
            let form6 = {};

            form1.marketing_name = data.marketing_name;
            form1.descriptive_name = data.descriptive_name;
            form1.other_common_name = data.other_common_name;
            form1.product_type = data.product_type;
            form1.factory_id = data.factory_id;
            form1.origin_country = data.origin_country;
            form1.manufacture_country = data.manufacture_country;
            form1.cas = data.cas;
            form1.sort = data.sort || 10;
            if (data.industry_usage_grade) {
              form1.industry_usage_grade = data.industry_usage_grade;
            }
            if (data.compendial_standard) {
              form1.compendial_standard = data.compendial_standard;
            }
            if (data.warehouses && data.warehouses.length > 0) {
              form1.warehouse = data.warehouses;
            }
            if (data.botanicals_additional_details) {
              form1.botanicals_additional_details =
                data.botanicals_additional_details;
            }

            // from2
            form2.description = data.property.description;
            if (data.property.appearance) {
              form2.appearance = data.property.appearance;
            }
            if (data.property.solubility) {
              form2.solubility = data.property.solubility;
            }

            if (data.property.composition_percent) {
              form2.composition_percent = data.property.composition_percent.map(
                item => {
                  item.composition = Number(item.composition);
                  return item;
                }
              );
            }

            if (data.property.particle_size) {
              form2.particle_size = data.property.particle_size.map(item => {
                item.yield = Number(item.yield);
                return item;
              });
            }

            if (data.property.storage_and_handling) {
              form2.storage_and_handling = data.property.storage_and_handling;
            }

            if (data.property.common_industries_served) {
              form2.common_industries_served =
                data.property.common_industries_served;
            }

            // form3
            if (data.specification.assay_claims) {
              form3.assay_claims = data.specification.assay_claims;
            }

            form3.chemical_formula = data.specification.chemical_formula;
            form3.molecular_weight = data.specification.molecular_weight;
            form3.processing_method = data.specification.processing_method;
            form3.starting_material = data.specification.starting_material;
            if (data.specification.solvents_used_in_production) {
              form3.solvents_used_in_production =
                data.specification.solvents_used_in_production;
            }

            // form4
            if (data.contaminant.microbial_contaminants_breakdown) {
              form4.microbial_contaminants_breakdown = data.contaminant.microbial_contaminants_breakdown.map(
                item => {
                  item.microbial_count = Number(item.microbial_count);
                  return item;
                }
              );
            }

            if (data.contaminant.heavy_metals_breakdown) {
              form4.heavy_metals_breakdown = data.contaminant.heavy_metals_breakdown.map(
                item => {
                  item.heavy_metal_count = Number(item.heavy_metal_count);
                  return item;
                }
              );
            }

            //form5
            if (data.documents) {
              form5.document = data.documents.map(item => item.id);
            }

            // form6
            if (data.video) {
              form6.video = data.video[0] && data.video[0].url;
            }

            if (data.images) {
              form6.images = data.images.map(img => img.url);
            }

            // this.form7.market_trend = data.market_trend;

            const cloneData = {
              ...form1,
              ...form2,
              ...form3,
              ...form4,
              ...form5,
              ...form6
            };

            if (item.status === -3) {
              _createDraft(cloneData)
                .then(() => {
                  this.$Modal.remove();
                  this.$Message.success("success");
                  this.queryMyProductList(); // refresh
                })
                .catch(({ message }) => {
                  this.$Modal.remove();
                  this.$Message.error(message);
                });
            } else {
              _createProduct(cloneData)
                .then(() => {
                  this.$Modal.remove();
                  this.$Message.success("success");
                  this.queryMyProductList(); // refresh
                })
                .catch(({ message }) => {
                  this.$Modal.remove();
                  this.$Message.error(message);
                });
            }
          });
        }
      });
    },

    unpublish(id) {
      _unpublishProduct({ id })
        .then(() => {
          this.$Message.success("success");
          this.queryMyProductList();
        })
        .catch(({ message }) => {
          this.$Message.error(message);
        });
    },

    publish(id) {
      _publishProduct({ id })
        .then(() => {
          this.$Message.success("success");
          this.queryMyProductList();
        })
        .catch(({ message }) => {
          this.$Message.error(message);
        });
    },
    pageChange(page) {
      this.page = page;
      this.queryMyProductList();
    },

    pageSizeChange(limit) {
      this.limit = limit;
      this.queryMyProductList();
    },
    queryMyProductList() {
      this.loading = true;

      _queryMyProductList({
        page: this.page,
        limit: this.limit,
        ...this.filter
      })
        .then(response => {
          const { data } = response;
          this.list = data.data;
          this.total = data.total;
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
  filters: {}
};
</script>

<style lang="less" scoped>
@media (min-width: 1600px) {
  .main {
    width: 1018px;
    margin: 0 auto;
  }
  .top .search {
    width: 650px;
    .input {
      width: 380px;
    }
  }
  .list-data {
    margin-left: 10px;
    .product {
      width: 229px;
      margin-right: 15px;
      .product-top {
        width: 229px;
        img {
          width: 100%;
        }
      }
    }
  }
}
@media (max-width: 1600px) and (min-width: 1400px) {
  .main {
    width: 750px;
    margin: 0 auto;
  }
  .top .search {
    width: 520px;
    .input {
      width: 260px;
    }
  }
  .list-data {
    margin-left: 10px;
    .product {
      width: 229px;
      margin-right: 20px;
      .product-top {
        width: 229px;
        img {
          width: 100%;
        }
      }
    }
  }
}
@media (max-width: 1400px) and (min-width: 1200px) {
  .main {
    width: 700px;
    margin: 0 auto;
  }
  .top .search {
    width: 480px;
    .input {
      width: 200px;
    }
  }
}
@media (max-width: 1200px) {
}
.homePage {
  background: #ffffff;
  margin-top: 120px;
  display: flex;
}
.sc-width {
  margin: 0 auto;
}

.list-data {
  display: flex;
  justify-content: flex-start;
  flex-flow: wrap;
  .product {
    height: 360px;
    background: #ffffff;
    border-radius: 5px;
    margin-top: 24px;
    cursor: pointer;
    .product-top {
      height: 125px;
      border-radius: 5px;
      img {
        /*width: 260px;*/
        height: 125px;
      }
    }
    .bottom {
      .logo {
        width: 68px;
        height: 68px;
        /*background: #FFFFFF;*/
        position: relative;
        top: -40px;
        left: 10px;
        .back {
          border-radius: 50%;
          width: 100%;
          height: 100%;
          text-align: center;
          vertical-align: middle;
          background: #ffffff;
          padding-top: 10px;
          img {
            width: 40px;
            height: 40px;
          }
        }
      }
      .title {
        font-size: 17px;
        font-weight: 500;
        color: #252729;
        position: relative;
        top: -47px;
        padding-left: 10px;
        max-height: 31px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .company {
        font-size: 12px;
        font-weight: 400;
        color: #808080;
        position: relative;
        top: -55px;
        left: 22px;
      }
      .label {
        font-size: 12px;
        font-weight: 500;
        color: #808080;
        position: relative;
        top: -80px;
        left: 22px;
      }
      .value {
        height: 9px;
        font-size: 14px;
        font-weight: 400;
        color: #808080;
        position: relative;
        top: -40px;
        left: 22px;
      }
      .tag {
        width: 100px;
        height: 25px;
        background: #ef842d;
        border-radius: 5px;
        position: relative;
        bottom: 50px;
        left: 50px;
        text-align: center;
        display: table-cell;
        vertical-align: middle;
        font-size: 14px;
        font-weight: 500;
        color: #ffffff;
        cursor: pointer;
        position: relative;
        top: -12px;
      }
    }
    .operate-divided {
      width: 100%;
      height: 1px;
      background: #b3b3b3;
      position: relative;
      bottom: -4px;
    }
  }
}
.top {
  display: flex;
  justify-content: space-between;
  margin-top: 67px;
  margin-top: 67px;
  /*margin-left: 48px;*/
  .search {
    justify-content: space-between;
    display: flex;
    .total {
      padding-top: 5px;
    }
    .input {
      height: 30px;
      background: #ffffff;
      border: 1px solid #ef7f21;
      border-radius: 5px;
      padding-left: 3px;
      padding-right: 3px;
      input {
        width: 100%;
        height: 100%;
        outline: none;
        border: none;
      }
    }
    .button {
      width: 120px;
      height: 30px;
      background: #ef7f21;
      border-radius: 5px;
      font-size: 16px;

      font-weight: 500;
      color: #ffffff;
      line-height: 18px;
      text-align: center;
      padding-top: 7px;
      cursor: pointer;
    }
  }
  .add {
    width: 140px;
    height: 30px;
    background: #ef7f21;
    border-radius: 5px;
    font-size: 14px;

    font-weight: 500;
    color: #ffffff;
    line-height: 18px;
    text-align: center;
    padding-top: 7px;
    cursor: pointer;
  }
}
.divided {
  height: 1px;
  background: #999999;
  margin-top: 30px;
  margin-bottom: 30px;
}
.page {
  text-align: center;
  margin-top: 30px;
}
.status {
  position: relative;
  bottom: -5px;
  left: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.operate {
  position: relative;
  bottom: -18px;
  left: 5px;
}
.active-product {
  /*box-shadow: 0 0 1px #8A9199;*/
  box-shadow: 0 2px 16px #8a9199, 0 0 1px #8a9199, 0 0 1px #8a9199;
}
.no-data {
  text-align: center;
  margin-top: 30px;
}
</style>
